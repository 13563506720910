var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_more"),
        visible: _vm.value,
        "destroy-on-close": true,
        closable: false,
        width: "620px"
      }
    },
    [
      _c(
        "a-form-model",
        {
          ref: "formModalDetail",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "wrapper-col": { span: 14 },
            "label-col": { span: 6 }
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_product_code") } },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(_vm._s(_vm.form.productCode || "-"))
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_product_name") } },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(_vm._s(_vm.form.productName || "-"))
              ])
            ]
          ),
          _c("a-form-model-item", { attrs: { label: _vm.$t("lbl_brand") } }, [
            _c("span", { staticClass: "ant-form-text" }, [
              _vm._v(_vm._s(_vm.form.brand || "-"))
            ])
          ]),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_qty"), prop: "qty" } },
            [
              _vm.tabDetailRow.fromDO
                ? _c("span", [
                    _vm._v(_vm._s(_vm._f("toDecimalQty")(_vm.form.qty)))
                  ])
                : _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      placeholder: _vm.$t("lbl_type_here"),
                      formatter: _vm.formatterNumber,
                      parser: _vm.reverseFormatNumber,
                      precision: _vm.DECIMAL_PLACES_QTY,
                      min: 0
                    },
                    on: { change: _vm.onChangeQty },
                    model: {
                      value: _vm.form.qty,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "qty", _vm._n($$v))
                      },
                      expression: "form.qty"
                    }
                  })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_uom"), prop: "uomId" } },
            [
              _vm.tabDetailRow.fromDO
                ? _c("span", [_vm._v(_vm._s(_vm.form.uom || "-"))])
                : _c("CSelectUomConv", {
                    attrs: { "prop-product-id": _vm.form.productId },
                    model: {
                      value: _vm.form.uomId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "uomId", $$v)
                      },
                      expression: "form.uomId"
                    }
                  })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_price"), prop: "price" } },
            [
              _vm.tabDetailRow.fromDO
                ? _c("span", [
                    _vm._v(_vm._s(_vm._f("currency")(_vm.form.price)))
                  ])
                : _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      placeholder: _vm.$t("lbl_type_here"),
                      formatter: _vm.formatterNumber,
                      parser: _vm.reverseFormatNumber,
                      precision: _vm.DECIMAL_PLACES_CURRENCY,
                      min: 0
                    },
                    on: { change: _vm.onChangePrice },
                    model: {
                      value: _vm.form.price,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "price", _vm._n($$v))
                      },
                      expression: "form.price"
                    }
                  })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_discount") } },
            [
              _vm.isDetail
                ? _c("a-space", [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.form.discount.percent)) +
                          "%"
                      )
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.form.discount.value))
                      )
                    ])
                  ])
                : _c(
                    "a-space",
                    [
                      _c("a-input-number", {
                        staticClass: "w-100",
                        attrs: {
                          value: _vm.form.discount.percent,
                          placeholder: _vm.$t("lbl_type_here"),
                          formatter: _vm.formatterPercent,
                          parser: _vm.reverseFormatPercent,
                          precision: _vm.DECIMAL_PLACES_CURRENCY,
                          min: 0,
                          max: 100
                        },
                        on: {
                          change: function(e) {
                            return _vm.onChangeDiscount(e, "percent")
                          }
                        }
                      }),
                      _c("a-input-number", {
                        staticClass: "w-100",
                        attrs: {
                          value: _vm.form.discount.value,
                          placeholder: _vm.$t("lbl_type_here"),
                          formatter: _vm.formatterNumber,
                          parser: _vm.reverseFormatNumber,
                          precision: _vm.DECIMAL_PLACES_CURRENCY,
                          min: 0
                        },
                        on: {
                          change: function(e) {
                            return _vm.onChangeDiscount(e, "value")
                          }
                        }
                      })
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_base_amount_dpp") } },
            [
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.baseAmount)))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-space",
            [
              _c("a-button", { on: { click: _vm.handleClose } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
              ]),
              !_vm.isDetail
                ? _c(
                    "a-button",
                    {
                      key: "save",
                      attrs: { type: "primary", icon: "save" },
                      on: { click: _vm.handleSave }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }