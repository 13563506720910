



































































































































import Vue from "vue";
import { formatCurrency, formatterNumber, formatterPercent, reverseFormatNumber, reverseFormatPercent } from "@/validator/globalvalidator";
import { mapActions, mapMutations, mapState } from "vuex";
import { Decimal } from "decimal.js-light";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { DECIMAL_PLACES_QTY, DECIMAL_PLACES_CURRENCY } from "@constant/global.constant";
export default Vue.extend({
  name: "InvoiceModalDetail",
  components: {
    // InvoiceIncomeTax: () => import(/*webpackPrefetch: true */"./InvoiceIncomeTax.vue"),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: undefined,
    }
  },
  data() {
    return {
      DECIMAL_PLACES_QTY,
      DECIMAL_PLACES_CURRENCY,
      form: {
        productCode: "",
        productName: "",
        productId: "",
        brand: "",
        qty: 0,
        uomId: undefined as string | undefined,
        uom: "",
        price: 0,
        discount: {
          percent: 0,
          value: 0,
        },
        incomeTaxId: undefined as string | undefined,
        incomeTaxRate: 0,
        incomeTaxValue: 0,
        baseAmount: 0,
      },
      rules: {
        qty: [{ required: true, type: "number", message: () => this.$t("lbl_validation_required_error"), trigger: "blur" }],
        price: [{ required: true, type: "number", message: () => this.$t("lbl_validation_required_error"), trigger: "blur" }],
        uomId: [{ required: true, message: () => this.$t("lbl_validation_required_error"), trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState({
      tabDetailRow: (st: any) => st.invoiceStore.tabDetailRow,
      tabDetailSource: (st: any) => st.invoiceStore.tabDetailSource,
      detailInvoice: (st: any) => st.invoiceStore.detailInvoice,
      storeForm: (st: any) => st.invoiceStore.form,
    }),
    isDetail(): boolean {
      return !!this.detailInvoice.status;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: "fillForm"
    },
  },
  methods: {
    formatCurrency,
    formatterNumber,
    reverseFormatNumber,
    reverseFormatPercent,
    formatterPercent,
    ...mapMutations({
      setTabDetailRow: "invoiceStore/SET_TAB_DETAIL_ROW",
      setTabDetailRowByKey: "invoiceStore/SET_TAB_DETAIL_ROW_BY_KEY",
    }),
    ...mapActions({
      resetTabDetailRow: "invoiceStore/RESET_TAB_DETAIL_ROW",
      actSetTabDetailRowByKey: "invoiceStore/ACT_SET_TAB_DETAIL_ROW_BY_KEY",
      calcProRatePricing: "invoiceStore/CALC_PRO_RATE_PRICING_V2",
    }),
    handleSave(): void {
      this.mapToStore();
      this.$emit("input", false);
    },
    mapToStore(): void {
      this.actSetTabDetailRowByKey({
        key: this.tabDetailRow.key,
        data: {
          ...this.tabDetailRow,
          baseAmount: this.form.baseAmount,
          uomId: this.form.uomId,
          uom: this.form.uom,
          qty: this.form.qty,
          price: this.form.price,
          percentDiscount: this.form.discount.percent,
          discountValue: this.form.discount.value,
          incomeTaxId: this.form.incomeTaxId,
          incomeTaxRate: this.form.incomeTaxRate,
          incomeTaxValue: this.form.incomeTaxValue,
        },
      });
    },
    resetForm(): void {
      this.form = {
        productCode: "",
        productName: "",
        productId: "",
        brand: "",
        qty: 0,
        uomId: undefined as string | undefined,
        uom: "",
        price: 0,
        discount: {
          percent: 0,
          value: 0,
        },
        incomeTaxId: undefined as string | undefined,
        incomeTaxRate: 0,
        incomeTaxValue: 0,
        baseAmount: 0,
      };
    },
    fillForm(): void {
      this.form = {
        productCode: this.tabDetailRow.productCode,
        productName: this.tabDetailRow.productName,
        productId: this.tabDetailRow.productId,
        brand: this.tabDetailRow.brand,
        qty: this.tabDetailRow.qty,
        uomId: this.tabDetailRow.uomId,
        uom: this.tabDetailRow.uom,
        price: this.tabDetailRow.price,
        discount: {
          percent: this.tabDetailRow.percentDiscount,
          value: this.tabDetailRow.discountValue,
        },
        incomeTaxId: this.tabDetailRow.incomeTaxId,
        incomeTaxRate: this.tabDetailRow.incomeTaxRate,
        incomeTaxValue: this.tabDetailRow.incomeTaxValue,
        baseAmount: this.tabDetailRow.baseAmount,
      };
    },
    onSelectIncomeTax({ meta }): void {
      this.form.incomeTaxRate = meta.rate;
      this.calcIncomeTaxAmount();
    },
    onChangeDiscount(e: number, source: "percent" | "value"): void {
      const gross = new Decimal(this.form.price || 0).times(this.form.qty || 0);
      if (source === "percent") {
        const percentage = new Decimal(e || 0).dividedBy(100);
        this.form.discount.value = gross.times(percentage).toNumber();
        this.form.discount.percent = e;
      } else {
        const value = new Decimal(e || 0);
        this.form.discount.percent = value.dividedBy(gross || 1).times(100).toNumber();
        this.form.discount.value = e;
      }
      this.calcBaseAmount();
    },
    onChangePrice(): void {
      this.calcBaseAmount();
    },
    onChangeQty(): void {
      this.calcBaseAmount();
    },
    calcIncomeTaxAmount(): void {
      const gross = new Decimal(this.form.qty || 0).times(this.form.price || 0);
      const discount = new Decimal(this.form.discount.value || 0);
      const incomeRatePercent = new Decimal(this.form.incomeTaxRate || 0).dividedBy(100);
      if (this.storeForm.taxType === TAX_CALCULATION.EXCLUSIVE) {
        this.form.incomeTaxValue = gross.minus(discount).times(incomeRatePercent).toNumber();
      } else if (this.storeForm.taxType === TAX_CALCULATION.INCLUSIVE) {
        this.form.incomeTaxValue = gross.times(incomeRatePercent).toNumber();
      } else {
        this.form.incomeTaxValue = 0;
      }
    },
    calcBaseAmount(): void {
      const gross = new Decimal(this.form.qty || 0).times(this.form.price || 0);
      const discount = new Decimal(this.form.discount.value || 0);
      this.form.baseAmount = gross.minus(discount).toNumber();
      this.calcProRatePricing();
    },
    handleClose(): void {
      this.$emit("input", false);
    },
  },
});
